
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AnimatedSheet, {Transition} from './AnimatedSheet.vue';

@Component({})
export default class AnimatedSheetGroup extends Vue {
    @Prop()
    activeIndex!: number;

    mounted() {
        this.updateChild(this.activeIndex, Transition.In);
    }

    @Watch('activeIndex')
    onIndexChange(val: number, oldVal: number) {
        const transitionOut = (val > oldVal) ? Transition.OutLeft : Transition.OutRight;
        this.updateChild(oldVal, transitionOut);

        const transitionIn = (val > oldVal) ? Transition.InRight : Transition.InLeft;
        this.updateChild(val, transitionIn);
    }

    updateChild(index: number, transition: Transition) {
        (this.$children[index] as AnimatedSheet).transitionTo(transition);
    }
}
