import Vue from 'vue';
import Vuetify from 'vuetify';
import de from 'vuetify/src/locale/de';
import 'vuetify/dist/vuetify.min.css';
import KindeysIcon from '@/components/icons/KindeysIcon.vue';
import SpinalIcon from '@/components/icons/SpinalIcon.vue';
import TapeIcon from '@/components/icons/TapeIcon.vue';
import NeuronIcon from '@/components/icons/NeuronIcon.vue';
import PointerIcon from '@/components/icons/PointerIcon.vue';
import ParkingIcon from '@/components/icons/ParkingIcon.vue';
import Deg360Icon from '@/components/icons/Deg360Icon.vue'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { de },
      current: 'de',
    },
    theme: {
      themes: {
        light: {
          primary: '#83BC26',
          secondary: '#FFCC00',
          accent: '#8c9eff',
          error: '#b71c1c',
        },
      },
      options: { customProperties: true }
    },
    icons: {
      values: {
        kidneys: {
          component: KindeysIcon,
        },
        spinal: {
          component: SpinalIcon,
        },
        tape: {
          component: TapeIcon,
        },
        neuron: {
          component: NeuronIcon,
        },
        pointer: {
          component: PointerIcon,
        },
        parking: {
          component: ParkingIcon,
        },
        deg360: {
          component: Deg360Icon,
        },
      }
    }
});
