
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import ListIconItem from '@/components/ListIconItem.vue';
import EmBar from '@/components/EmBar.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer,
        ListIconItem,
        EmBar
    }
})
export default class PhysioPricing extends Vue {
}
