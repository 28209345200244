
import { Component, Vue } from 'vue-property-decorator';
import SanosLogo from '@/components/SanosLogo.vue';
import { delay } from '@/timing';

@Component({
    components: {
        SanosLogo
    }
})
export default class TransitionOverlay extends Vue {

    phase = '';
    show = true;

    async mounted() {
        await delay(3000);
        this.phase = 'phase2';
        await delay(4000);
        this.phase = 'phase3';
        await delay(5000);
        this.$router.replace({ name: 'home' });
    }
}
