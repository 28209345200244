
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer
    }
})
export default class Team extends Vue {

}
