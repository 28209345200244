
import { Component, VModel, Vue } from 'vue-property-decorator';
import ListIconItem from '@/components/ListIconItem.vue';

@Component(
    {components: {
        ListIconItem
    }
})
export default class EmergencyAppointmentDialog extends Vue {

    @VModel({ type: Boolean })
    dialogVisible!: boolean;
}
