
import { Component, Vue } from 'vue-property-decorator';
import SanosLogo from '@/components/SanosLogo.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';

@Component({
  components: {
    SanosLogo,
    DefaultContainer
  }
})
export default class AppFooter extends Vue {
}
