
import EmergencyAppointmentDialog from '@/components/EmergencyAppointmentDialog.vue'
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import SanosLogo from '@/components/SanosLogo.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer,
        SanosLogo,
        EmergencyAppointmentDialog
    }
})
export default class Termine extends Vue {

    emergencyAppointmentDialogVisible = false;
}
