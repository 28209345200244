
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ContactCard extends Vue {

    @Prop()
    title!: string;

    @Prop()
    icon!: string;
}
