
import { Component, Vue } from 'vue-property-decorator';
import ContactCard from '../components/ContactCard.vue';
import EmergencyAppointmentDialog from '@/components/EmergencyAppointmentDialog.vue'

@Component({components: {
    ContactCard,
    EmergencyAppointmentDialog
  }})
export default class ContactBar extends Vue {

      emergencyAppointmentDialogVisible = false;
}
