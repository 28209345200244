
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ExpandablePanel extends Vue {

    expanded = false;
    height = "0px";

    toggleExpansion() {
        const body = this.$refs.body as HTMLDivElement;
        if (this.expanded) {
            this.height = `${body.scrollHeight}px`;
            this.expanded = false;
            this.$nextTick(() => this.sleep(0).then(() => this.height = "0px"));
        } else {
            this.height = `${body.scrollHeight}px`;
            this.expanded = true;
            this.$nextTick(() => this.sleep(300).then(() => this.height = ""));
        }
    }

    get triggerIcon() {
        return this.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down';
    }

    get triggerText() {
        return this.expanded ? 'weniger' : 'mehr';
    }

    get hasExpandableContent() {
      return !!this.$slots['expandable'];
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
