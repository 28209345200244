import { render, staticRenderFns } from "./PhysioTherapy.vue?vue&type=template&id=70109412&scoped=true"
import script from "./PhysioTherapy.vue?vue&type=script&lang=ts"
export * from "./PhysioTherapy.vue?vue&type=script&lang=ts"
import style0 from "./PhysioTherapy.vue?vue&type=style&index=0&id=70109412&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70109412",
  null
  
)

export default component.exports