
import EmergencyAppointmentDialog from '@/components/EmergencyAppointmentDialog.vue'
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import ListTextItem from '@/components/ListTextItem.vue';
import ExpansionItem from '@/components/ExpansionItem.vue';
import ListIconItem from '@/components/ListIconItem.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer,
        EmergencyAppointmentDialog,
        ListTextItem,
        ExpansionItem,
        ListIconItem
    }
})
export default class OsteoTherapy extends Vue {

    emergencyAppointmentDialogVisible = false;
}

