
import { Component, Ref, Vue } from 'vue-property-decorator';
import DefaultContainer from '@/components/DefaultContainer.vue';
import AnimatedSheet from '@/components/AnimatedSheet.vue'
import AnimatedSheetGroup from '@/components/AnimatedSheetGroup.vue';

@Component({components: {
    DefaultContainer,
    AnimatedSheetGroup,
    AnimatedSheet
  }})
export default class Philosophy extends Vue {
    @Ref('slider')
    readonly slider!: HTMLDivElement;

    activeIndex = 0;

    sliderWidth = 0;
    itemCenters: number[] = [];
    itemRights: number[] = [];

    items = [
        {
            title: 'Unsere Philosophie',
            subtitle: 'Geimeinsam stärker.<br/>Grenzen kennen.'
        },
        {
            title: 'Osteopathie',
            subtitle: 'Impulse setzen.<br/>Zeit geben.'
        },
        {
            title: 'Physiotherapie',
            subtitle: 'Prävention & Rehabilitation.'
        }
    ];

    async created() {
        await Vue.nextTick();
        this.refreshSizes();
        window.addEventListener("resize", this.refreshSizes);
        window.addEventListener("orientationchange", this.refreshSizes);
    }

    destroyed() {
        window.removeEventListener("resize", this.refreshSizes);
        window.removeEventListener("orientationchange", this.refreshSizes);
    }

    async refreshSizes() {
        setTimeout(() => {
            this.sliderWidth = this.slider.clientWidth;

            this.itemCenters = Array.from(this.slider.children).map(e => this.centerOf(e));
            this.itemRights = Array.from(this.slider.children).map(e => (e as HTMLElement).offsetLeft + e.clientWidth);
        }, 0);
    }

    centerOf(e: Element) {
        const element = e as HTMLElement;
        return element.offsetLeft + element.clientWidth / 2;
    }

    offsetValue() {
        if (this.activeIndex == 0 || this.sliderWidth >= this.lastOf(this.itemRights)) {
            return 0;
        } else if (this.activeIndex == this.items.length - 1) {
            return this.sliderWidth - this.itemRights[this.activeIndex];
        } else {
            return this.sliderWidth / 2 - this.itemCenters[this.activeIndex];
        }
    }

    get offsetTransformValue() {
        return `translateX(${this.offsetValue()}px)`;
    }

    lastOf<T>(arr: T[]) {
        return arr[arr.length - 1];
    }

    moveActiveIndex(delta: number) {
        this.activeIndex = Math.min(this.items.length - 1, Math.max(0, this.activeIndex + delta));
    }

    get isFirstActive() {
        return this.activeIndex == 0;
    }

    get isLastActive() {
        return this.activeIndex === this.items.length - 1;
    }
}
