
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    inheritAttrs: false
})
export default class ListIconItem extends Vue {

    @Prop()
    icon!: string;

    @Prop({default: 'primary'})
    color!: string;
}
