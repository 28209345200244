
import { Component, Prop, Vue } from 'vue-property-decorator';
import CategoryIcon from '@/components/CategoryIcon.vue'

@Component({
    inheritAttrs: false,
    components: {
        CategoryIcon
    }
})
export default class ListCategoryItem extends Vue {

    @Prop()
    cat!: string;
}
