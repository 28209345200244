
import { Component, Prop, Vue } from 'vue-property-decorator';
import DefaultContainer from '@/components/DefaultContainer.vue';

@Component({
    components: {
        DefaultContainer
    }
})
export default class PageTitle extends Vue {

    @Prop()
    image!: string;

    @Prop()
    position!: string;

    @Prop()
    title!: string;

    @Prop()
    subtitle!: string;
}
