
import { Component, Vue } from 'vue-property-decorator';
import SanosLogo from '@/components/SanosLogo.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import ContactBar from '@/components/ContactBar.vue';
import TeamBar from '@/views/home/TeamBar.vue';
import Philosophy from './Philosophy.vue';
import ItemsCard from '@/components/ItemsCard.vue';
import ListCategoryItem from '@/components/ListCategoryItem.vue';
import CategoryIcon from '@/components/CategoryIcon.vue';
import EmBar from '@/components/EmBar.vue';
import BusinessViewBar from '@/components/BusinessViewBar.vue';

@Component({
  components: {
    SanosLogo,
    DefaultContainer,
    ContactBar,
    TeamBar,
    Philosophy,
    ItemsCard,
    ListCategoryItem,
    CategoryIcon,
    EmBar,
    BusinessViewBar
  }
})
export default class Home extends Vue {

  emergencyAppointmentDialogVisible = false;

  get bgImageStyle() {
    return {
      backgroundImage: `url(${require('@/assets/home.jpg')})`,
      backgroundPosition: '70% 20%',
    };
  }
}
