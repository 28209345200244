
import { Component, Prop, Vue } from 'vue-property-decorator';
import ExpandablePanel from '@/components/ExpandablePanel.vue';

@Component({
    components: {
        ExpandablePanel
    }
})
export default class ItemsCard extends Vue {

    @Prop()
    iconName!: string;

    @Prop()
    title!: string;
}
