
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import OsteoIcon from '@/components/OsteoIcon.vue';
import ItemsCard from '@/components/ItemsCard.vue';
import SanosLogo from '@/components/SanosLogo.vue';
import ExpansionItem from '@/components/ExpansionItem.vue';
import EmBar from '@/components/EmBar.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer,
        OsteoIcon,
        ItemsCard,
        SanosLogo,
        ExpansionItem,
        EmBar
    }
})
export default class Osteo extends Vue {

}
