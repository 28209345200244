import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import Home from '../views/home/Home.vue'
import TransitionOverlay from '../views/TransitionOverlay.vue'
import Termine from '../views/praxis/termine/Termine.vue'
import Team from '../views/praxis/team/Team.vue'
import Kontakt from '../views/praxis/kontakt/Kontakt.vue'
import Impressum from '../views/praxis/impressum/Impressum.vue'
import Osteo from '../views/osteopathie/allgemein/Osteo.vue'
import OsteoTherapy from '../views/osteopathie/behandlung/OsteoTherapy.vue'
import OsteoPrices from '../views/osteopathie/kosten/OsteoPrices.vue'
import PhysioTherapy from '../views/physio/behandlung/PhysioTherapy.vue'
import PhysioPricing from '../views/physio/kosten/PhysioPricing.vue'
import { Position } from 'vue-router/types/router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/physio.fit',
    name: 'transition',
    component: TransitionOverlay,
    meta: {
      footerInvisible: true
    }
  },
  {
    path: '/praxis/termine',
    name: 'practice.appointments',
    component: Termine,
    meta: {
      title: 'Termine'
    }
  },
  {
    path: '/praxis/team',
    name: 'practice.team',
    component: Team,
    meta: {
      title: 'Team'
    }
  },
  {
    path: '/praxis/kontakt',
    name: 'practice.contact',
    component: Kontakt,
    meta: {
      title: 'Kontakt'
    }
  },
  {
    path: '/praxis/impressum',
    name: 'practice.imprint',
    component: Impressum,
    meta: {
      title: 'Impressum'
    }
  },
  {
    path: '/osteopathie/allgemein',
    name: 'osteo.description',
    component: Osteo,
    meta: {
      title: 'Was ist Osteopathie? | Osteopathie'
    }
  },
  {
    path: '/osteopathie/behandlung',
    name: 'osteo.therapy',
    component: OsteoTherapy,
    meta: {
      title: 'Behandlungskonzept | Osteopathie'
    }
  },
  {
    path: '/osteopathie/kosten',
    name: 'osteo.prices',
    component: OsteoPrices,
    meta: {
      title: 'Kosten / Erstattung | Osteopathie'
    }
  },
  {
    path: '/physiotherapie/behandlung',
    name: 'physio.therapy',
    component: PhysioTherapy,
    meta: {
      title: 'Behandlungsangebot | Physiotherapie'
    }
  },
  {
    path: '/physiotherapie/kosten',
    name: 'physio.pricing',
    component: PhysioPricing,
    meta: {
      title: 'Kosten / Erstattung | Physiotherapie'
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to: Route, from: Route, savedPosition: Position | void) {
    if (to.hash) {
      return { selector: to.hash }
    } else
      return savedPosition ?? { x: 0, y: 0 };
    }
})

export default router;
