
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import SanosLogo from '@/components/SanosLogo.vue';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import {latLng, latLngBounds, Browser, MapOptions} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BusinessViewBar from '@/components/BusinessViewBar.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer,
        SanosLogo,
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        BusinessViewBar
    }
})
export default class Kontakt extends Vue {

    home = latLng(48.4565883257468, 13.4375117163188);
    parking = latLng(48.4567829514978, 13.437928255539);
    bounds = latLngBounds([
        [48.430320204271588, 13.331520368107812],
        [48.489499322597396, 13.554911856476428]
    ]);
    currentCenter = this.home;
    currentZoom = 15;
    map?: MapOptions = { zoom: 0 };
    attribution = '&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a>-Mitwirkende';

    created() {
        this.$nextTick(() => this.map = (this.$refs.ll as any)?.mapObject);
    }

    zoomUpdate(zoom: number) {
      this.currentZoom = zoom;
    }

    centerUpdate(center: any) {
      this.currentCenter = center;
    }

    options() {
        return { 
            zoomSnap: 1,
            dragging: !Browser.mobile,
            tap: !Browser.mobile,
            minZoom: 13,
            maxZoom: 16
        } as MapOptions;
    }
}
