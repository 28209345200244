
import { Component, Vue, Watch } from 'vue-property-decorator';
import { delay } from '@/timing';
import SanosLogo from '@/components/SanosLogo.vue';

@Component({
    components: {
        SanosLogo
    }
})
export default class NavDrawer extends Vue {

    isOpen = false;
    isAutoClosing = false;

    currentTimeout = 0;
    lastOpenedAt = 0;

    startDelayedClose() {
        if (this.isOpen) {
            this.isAutoClosing = true;
            this.delay(1500).then(this.closeDrawer);
        }
    }

    cancelDelayedClose() {
        this.isAutoClosing = false;
        clearTimeout(this.currentTimeout);
    }

    async toggleOpen() {
        if (this.isOpen && this.currentMillis() - this.lastOpenedAt > 400) {
            this.closeDrawer();
        } else {
            this.openDrawer();
        }
    }

    openDrawer() {
        delay(0).then(() => {
            this.cancelDelayedClose();
            this.lastOpenedAt = this.currentMillis();
            this.isOpen = true;
        });
    }

    closeDrawer() {
        this.isOpen = false;
    }

    delay(millis: number) {
        return new Promise(res => {
            this.currentTimeout = setTimeout(res, millis);
        });
    }

    currentMillis() {
        return new Date().valueOf();
    }

    @Watch('$route')
    onRouteUpdate() {
        this.delay(200).then(this.closeDrawer);
    }
}
