
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import ListIconItem from '@/components/ListIconItem.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer,
        ListIconItem
    }
})
export default class Termine extends Vue {
}
