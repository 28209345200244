
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import DefaultContainer from '@/components/DefaultContainer.vue';
import EmBar from '@/components/EmBar.vue';

@Component({
    components: {
        PageTitle,
        DefaultContainer,
        EmBar
    }
})
export default class OsteoTherapy extends Vue {}

