
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ListTextItem extends Vue {

    @Prop()
    icon!: string;

    @Prop()
    title!: string;

    @Prop()
    subtitle!: string;

}
